.header_container {
  text-align: center;
}

.heading {
  font-family: 'Segoe UI', 'Segoe UI_EmbeddedFont', 'Segoe UI_MSFontService',
    sans-serif;
  font-size: 1.75rem;
  font-weight: 600;
  @media screen and (max-width: 768px) {
    font-size: 1.25rem;
  }
}

.description {
  font-family: 'Segoe UI', 'Segoe UI_EmbeddedFont', 'Segoe UI_MSFontService',
    sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: #bbbbbb;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
}
.faq_container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.faq_card_container {
  width: 80%;
  margin-top: 2rem;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 1rem;
  }
}

.wrapper {
  height: 100%;
  max-height: 500px;
  padding-bottom: 64px;
}

.headingWrapper {
  display: flex;
  align-items: center;
  .heading {
    font-weight: bold;
    font-size: 16px;
  }
  .count {
    color: #007aff;
    font-size: 25px;
    margin-right: 15px;
    font-weight: bold;

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }
}

.content {
  margin-left: 45px;
  color: gray;
  @media screen and (max-width: 768px) {
    margin-left: 35px;
    font-size: 14px;
  }
}

.subTitle {
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }
}
.expand {
  height: 28px;
  width: 28px;
  border: 2px solid #bbb;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
}
