.getting_started_container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.getting_started_card_container {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header_container {
  text-align: center;
}

.heading {
  font-family: 'Segoe UI', 'Segoe UI_EmbeddedFont', 'Segoe UI_MSFontService',
    sans-serif;
  font-size: 1.75rem;
  font-weight: 600;
  @media screen and (max-width: 768px) {
    font-size: 1.25rem;
  }
}

.description {
  font-family: 'Segoe UI', 'Segoe UI_EmbeddedFont', 'Segoe UI_MSFontService',
    sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: #bbbbbb;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
}
.getting_started_icon {
  margin: 2rem 0;
  width: 30%;
  @media screen and (max-width: 1024px) {
    width: 40%;
  }
  @media screen and (max-width: 768px) {
    width: 50%;
  }
}
