.header_container {
  text-align: center;
}

.heading {
  font-family: 'Segoe UI', 'Segoe UI_EmbeddedFont', 'Segoe UI_MSFontService',
    sans-serif;
  font-size: 1.75rem;
  font-weight: 600;
  @media screen and (max-width: 768px) {
    font-size: 1.25rem;
  }
}

.description {
  font-family: 'Segoe UI', 'Segoe UI_EmbeddedFont', 'Segoe UI_MSFontService',
    sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: #bbbbbb;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
}
.income_container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.income_card_container {
  width: 80%;
  margin-top: 2rem;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 1rem;
  }
}
.income_card {
  padding: 0.5rem 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  display: flex;
  margin: 1rem 0;
  height: 6rem;
  background-color: white;
}
.income_card_icon {
  display: flex;
  place-content: center;
  place-items: center;
  img {
    width: 50px;
    height: 50px;
  }
}
.card_description {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  div:first-child {
    font-size: 1.25rem;
    text-align: left;
  }
  div:last-child {
    align-self: flex-start;
    font-size: 1rem;
    color: #aaaaaa;
  }
  @media screen and (max-width: 1024px) {
    div:first-child {
      font-size: 1.15rem;
    }
    div:last-child {
      font-size: 0.9rem;
    }
  }
  @media screen and (max-width: 768px) {
    div:first-child {
      font-size: 1rem;
    }
    div:last-child {
      font-size: 0.9rem;
    }
  }
}
