.quick_tour_home {
  display: flex;
  flex-direction: column;
  padding-bottom: 7.5rem;
}
.quick_tour_header {
  display: flex;
  width: 100%;
  height: 4rem;
  padding: 0 2rem;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 0 1rem;
  }
}
.homeLink {
  display: flex;
  align-items: center;
  span {
    color: #000000;
    margin-left: 0.25rem;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    font-weight: 600;
    img {
      width: 16px;
    }
  }
  @media screen and (max-width: 768px) {
    .homeLink {
      span {
        font-size: 1rem;
        margin-left: 0rem;

        img {
          width: 10px;
        }
      }
    }
  }
}

.quick_tour_step {
  width: 25%;
  margin-top: 4rem;
  align-self: center;
  @media screen and (max-width: 1024px) {
    width: 40%;
    margin-top: 3rem;
  }
  @media screen and (max-width: 768px) {
    width: 80%;
    margin-top: 1rem;
  }
}

.quick_tour_content {
  width: 45%;
  align-self: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
  @media screen and (max-width: 1024px) {
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    width: 95%;
  }
  // display: flex;
  // justify-content: center;
}

.action_container {
  padding: 1rem;
  width: 100%;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  height: 7.5rem;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.button_container {
  width: 40%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
  @media screen and (max-width: 768px) {
    width: 95%;
  }
  div {
    button {
      width: 8rem;
      border: 1px solid #157dfc;
      padding: 0.75rem 0;
      border-radius: 4rem;
      color: #157dfc;
      background: #fff;
    }
    button.button_done {
      background-color: #157dfc;
      color: white;
    }
  }
}
.copyright {
  font-size: 1rem;
  text-align: center;
  margin-top: 0.5rem;
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
}
