.header_container {
  text-align: center;
  position: relative;
}
.switch_container {
  position: absolute;
  top: -10px;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  div {
    color: #bbb;
    margin-bottom: 0.5rem;
  }
  @media screen and (max-width: 1445px) {
    position: static;
  }
}
.switch_buttons {
  display: flex;
  div {
    display: flex;
    .switch_icon {
      width: 32px;
      height: 32px;
    }
  }
}
.heading {
  font-family: 'Segoe UI', 'Segoe UI_EmbeddedFont', 'Segoe UI_MSFontService',
    sans-serif;
  font-size: 1.75rem;
  font-weight: 600;
}

.subscription_container {
  width: 100%;
  margin-top: 2rem;
  overflow-x: auto;
  @media screen and (max-width: 1445px) {
    margin-top: 1rem;
  }
}
.subscription_os_container {
  display: flex;
  gap: 1rem;
}
.subscription_card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 5px;
  transition: 300ms;
  img {
    position: absolute;
    top: 1rem;
    width: 6.5rem;
  }
  div {
    width: 100%;
  }
}
.plan_label {
  padding: 0.4rem 0.5rem;
  color: white;
  text-align: center;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}
.subscription_inner_card {
  height: 10rem;
  padding: 4rem 1rem 1rem 1rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      font-weight: 600;
      font-size: 0.8rem;
    }
  }
}
.bottom_border {
  height: 1.5rem;
  width: 100%;
  @media screen and (max-width: 1445px) {
    width: 670px;
  }
}
.subscription_details_container {
  display: flex;
  width: 100%;
  gap: 8px;
  flex-direction: row;
  div {
    width: 100%;
    div {
      font-size: 1.4rem;
      font-weight: 600;
      margin-top: 1rem;
      color: #696969;
    }
    ul {
      list-style-position: outside;
      li {
        font-size: 1.2rem;
        color: #696969;
      }
    }
  }
  @media screen and (max-width: 1445px) {
    flex-direction: column;
    padding: 1rem;
  }
}
